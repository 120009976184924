define('tripcki-planner/services/paper-theme', ['exports', 'ember-paper/services/paper-theme'], function (exports, _paperTheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperTheme.default;
    }
  });
});