define('tripcki-planner/controllers/lissabon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    options: ['Welkom', 'Programma', 'Wist-je-datjes', 'Musea', 'Restaurants', 'Sightseeing', 'Uitgaan', 'Snelcursus', 'Fotospeurtocht', 'Sponsoren', 'Contact'],
    rightSideBarOpen: false,

    actions: {
      toggle(propName) {
        this.toggleProperty(propName);
      },

      openSomething(item) {
        if (item == 'Wist-je-datjes') {
          this.send('Feitjes');
        } else {
          this.send(`${item}`);
        }
      },

      Welkom() {
        this.transitionToRoute('lissabon.welkom');
      },

      Programma() {
        this.transitionToRoute('lissabon.programma');
      },

      Feitjes() {
        this.transitionToRoute('lissabon.feitjes');
      },

      Musea() {
        this.transitionToRoute('lissabon.musea');
      },

      Restaurants() {
        this.transitionToRoute('lissabon.restaurants.middag');
      },

      Sightseeing() {
        this.transitionToRoute('lissabon.sightseeing.sights');
      },

      Uitgaan() {
        this.transitionToRoute('lissabon.uitgaan.barretjes');
      },

      Snelcursus() {
        this.transitionToRoute('lissabon.snelcursus');
      },

      Fotospeurtocht() {
        this.transitionToRoute('lissabon.speurtocht.uitleg');
      },

      Sponsoren() {
        this.transitionToRoute('lissabon.sponsoren');
      },

      Contact() {
        this.transitionToRoute('lissabon.contact');
      }
    }
  });
});