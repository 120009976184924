define('tripcki-planner/controllers/lissabon/sightseeing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appController: Ember.inject.controller('application'),
    currentRouteName: Ember.computed.reads('appController.currentRouteName'),

    actions: {
      noop() {
        return;
      }
    }
  });
});