define('tripcki-planner/router', ['exports', 'tripcki-planner/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('lissabon', function () {
      this.route('welkom');
      this.route('programma');
      this.route('feitjes');
      this.route('musea');
      this.route('restaurants', function () {
        this.route('middag');
        this.route('avond');
        this.route('altijd');
      });
      this.route('sightseeing', function () {
        this.route('sights');
        this.route('streetart');
      });
      this.route('uitgaan', function () {
        this.route('barretjes');
        this.route('clubs');
      });
      this.route('speurtocht', function () {
        this.route('uitleg');
        this.route('pose');
        this.route('instagram');
        this.route('attributen');
      });
      this.route('snelcursus');
      this.route('sponsoren');
      this.route('contact');
    });
  });

  exports.default = Router;
});